// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_GItd0,.content_Zjhuo,.left_LB1rW,.menu_D1MEU,.right_Prz5N{display:flex}.container_GItd0{flex-grow:1;overflow-x:auto;position:relative;width:100%}.left_LB1rW{width:14rem}.left_LB1rW,.menu_D1MEU{height:100%}.left_LB1rW,.menu_D1MEU,.right_Prz5N{flex-direction:column}.right_Prz5N{margin-left:var(--layout-results-split-left-width);width:calc(100% - 14rem)}.content_Zjhuo{display:flex;flex-direction:column;padding:1rem;width:100%}.overflow_gy4H1{overflow:auto;scroll-behavior:smooth;-webkit-overflow-scrolling:touch;scrollbar-color:var(--color-accent-500) var(--color-accent-100);scrollbar-width:thin}.overflow_gy4H1::-webkit-scrollbar{height:1rem}.overflow_gy4H1::-webkit-scrollbar-track{background:var(--color-accent-100)}.overflow_gy4H1::-webkit-scrollbar-thumb{background-color:var(--color-accent-500);border:.25rem solid var(--color-accent-100)}.overflow_gy4H1::-webkit-scrollbar-thumb:hover{border-width:.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_GItd0",
	"content": "content_Zjhuo",
	"left": "left_LB1rW",
	"menu": "menu_D1MEU",
	"right": "right_Prz5N",
	"overflow": "overflow_gy4H1"
};
module.exports = ___CSS_LOADER_EXPORT___;
